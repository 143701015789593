import { useState } from "react"
import { useNavigate } from "react-router-dom"
import supabase from "../config/supabaseClient"
import SmoothieCard from "../components/SmoothieCard"

const Create = () => {

  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [method, setMethod] = useState('')
  const [rating, setRating] = useState('')
  const [formError, setFormError] = useState(null)


  const handleSubmit = async (e) => {
    e.preventDefault()

    if(!title || !method || !rating){
      setFormError('Please fill in all fields correctly')
      return
    }

    
    const {data, error} = await supabase
      .from('smoothies')
      .insert([{title, method, rating}])
      .select()

    console.log('Response from Supabase:', { data, error });

    if (data){
      console.log(data)
      setFormError(null)
      navigate("/")
    }

    if (error){
      console.log(error)
      setFormError('Please fill in all the fields correctly')
    }
    
    
  }

  return (
    <div className="page create">
      <form onSubmit={handleSubmit}>
        <label htmlFor="title">Title:</label>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <label htmlFor="method">Method:</label>
        <textarea
          id="method"
          value={method}
          onChange={(e) => setMethod(e.target.value)}
        />

        <label htmlFor="rating">Rating:</label>
        <input
          type="number"
          id="rating"
          value={rating}
          onChange={(e) => setRating(e.target.value)}
        />

        <button>Create Smoothie Recipe</button>

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  )
}

export default Create